
























































































































import {
  ref,
  defineComponent,
  computed,
} from '@vue/composition-api';
import Store from '@/store';
import { getElementId } from '@/utils';
import { ILookupType, ITableLookupsRecord } from '@/types';
import TableLookupsDeleteDialog from '@/components/TableLookups/TableLookupsDeleteDialog.vue';

export default defineComponent({
  components: { TableLookupsDeleteDialog },
  setup() {
    const headers = [
      { text: 'Type', value: 'type', align: 'start' },
      { text: 'Code', value: 'key' },
      { text: 'Description', value: 'value', sortable: false },
      {
        text: 'Actions', value: 'actions', sortable: false, width: '140px',
      },
    ];

    const paginationChanging = ref(false);

    const listItems = computed(() => Store.getters['tableLookups/getData'] as ITableLookupsRecord[]);
    const isLoading = computed(() => Store.getters['tableLookups/getLoading'] as boolean);
    const pagination = computed(() => Store.getters['tableLookups/getPagination']);

    const lookupTypeOptions = computed(() => Store.getters['tableLookups/getLookupTypes'] as ILookupType[]);

    const filterObject = computed(() => Store.getters['tableLookups/getFilter']);

    const openedPanel = ref([]);

    const loadData = async () => {
      await Store.dispatch('tableLookups/loadTableLookups');
    };

    const onFilterChange = () => {
      Store.dispatch('tableLookups/setPaginationPage', 1);
      loadData();
    };

    const getLookupTypeText = (listTypeValue: string) => {
      const listTypeObj = lookupTypeOptions.value.find((item) => item.type === listTypeValue);
      return listTypeObj?.text || '';
    };

    const onPageChange = async (page: number) => {
      paginationChanging.value = true;
      await Store.dispatch('tableLookups/setPaginationPage', page);
      await loadData();
      paginationChanging.value = false;
    };

    const onItemsPerPageChange = async (itemsPerPage: number) => {
      paginationChanging.value = true;
      await Store.dispatch('tableLookups/setPaginationItemsPerPage', itemsPerPage);
      await loadData();
      paginationChanging.value = false;
    };

    const onColSortBy = (sortBy: string) => {
      const sortByValue = sortBy || '';
      Store.dispatch('tableLookups/setFilterSortBy', sortByValue);
      loadData();
    };

    const onColSortDesc = (isDesc: boolean[]) => {
      const isDescValue = isDesc.length && isDesc[0] ? '-' : '';
      Store.dispatch('tableLookups/setFilterIsDesc', isDescValue);
      loadData();
    };

    const loadLookupTypes = () => {
      Store.dispatch('tableLookups/loadLookupTypes');
    };

    loadData();
    loadLookupTypes();

    return {
      headers,
      lookupTypeOptions,
      listItems,
      openedPanel,
      filterObject,
      getElementId,
      isLoading,
      getLookupTypeText,
      pagination,
      onPageChange,
      onItemsPerPageChange,
      onColSortBy,
      onColSortDesc,
      onFilterChange,
      paginationChanging,
    };
  },
});




























































import {
  defineComponent, ref, computed, PropType,
} from '@vue/composition-api';
import { ITableLookupsRecord } from '@/types';
import Store from '@/store';

export default defineComponent({
  props: {
    lookupData: {
      type: Object as PropType<ITableLookupsRecord>,
      default: () => {},
    },
  },
  setup(props) {
    const dialog = ref(false);
    const isLoading = computed(() => Store.getters['tableLookups/getLoading']);

    const closeDialog = () => {
      dialog.value = false;
    };

    const deleteLookup = async () => {
      await Store.dispatch('tableLookups/deleteLookupItem', props.lookupData.id);
      closeDialog();
    };

    return {
      dialog,
      closeDialog,
      deleteLookup,
      isLoading,
    };
  },
});

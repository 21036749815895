




































































































































































import {
  defineComponent, reactive, ref, computed, PropType,
} from '@vue/composition-api';
import { IReferenceListRecord, ITemplateSearchRecord, IVComboboxItem } from '@/types';
import Store from '@/store';

export default defineComponent({
  props: {
    action: {
      type: String,
      required: true,
    },
    listData: {
      type: Object as PropType<IReferenceListRecord>,
      default: () => {},
    },
  },
  setup(props) {
    const dialog = ref(false);
    const form = ref();
    const isLoading = computed(() => Store.getters['referenceList/getLoading']);

    const dialogTitle = computed(() => {
      if (props.action === 'create') return 'Create a New List';
      return `Edit ${props.listData.name}`;
    });

    const listTypes: IVComboboxItem[] = [
      { text: 'Labour', value: 'labour' },
      { text: 'Plant', value: 'plant' },
      { text: 'Materials', value: 'material' },
      { text: 'ILOS', value: 'ilos' },
    ];

    const defaultData = {
      id: '',
      name: '',
      type: '',
      workOrderTemplates: [],
      startDate: '',
      endDate: '',
    };

    const newListData = reactive(defaultData);

    const publishedTemplates = computed(() => {
      const items = Store.getters['templateList/getPublishedTemplatesData'] as ITemplateSearchRecord[];
      return items.map((template) => ({
        identifier: template.identifier,
        id: template.id,
      }));
    });

    const existingLists = computed(() => Store.getters['referenceList/getData'] as IReferenceListRecord[]);

    const startEndPicker = reactive({
      value: [] as string[],
      menu: false,
    });

    const startEndDate = ref('');

    const setListData = () => {
      const fromDate = new Date(props.listData.startDate).toLocaleDateString();
      const toDate = new Date(props.listData.endDate).toLocaleDateString();
      startEndDate.value = `${fromDate} ~ ${toDate}`;

      Object.assign(newListData, props.listData);
    };

    const dateRangeOverlaps = (firstStart: string, firstEnd: string, secondStart: string, secondEnd: string) => {
      if (firstStart <= secondStart && secondStart <= firstEnd) return true;
      if (firstStart <= secondEnd && secondEnd <= firstEnd) return true;
      if (secondStart < firstStart && firstEnd < secondEnd) return true;
      return false;
    };

    const rules = {
      required: (value: string): boolean | string => !!value || 'This field is required',
      noDatesOverlap: () => {
        const listsOfType = existingLists.value.filter((refList) => refList.type === newListData.type);
        if (startEndPicker.value.length === 0 || !newListData.type || listsOfType.length === 0) return true;

        const result = listsOfType.some((list) => {
          if (newListData.id !== list.id) {
            return dateRangeOverlaps(newListData.startDate, newListData.endDate, list.startDate, list.endDate);
          }
          return false;
        });
        return !result || 'Date range overlaps another list of the same type';
      },
    };

    const validateListData = computed((): boolean => {
      if (!newListData.name || !newListData.type || !newListData.startDate || !newListData.endDate
        || newListData.workOrderTemplates.length === 0 || typeof rules.noDatesOverlap() === 'string') return false;
      return true;
    });

    const onStartEndCancel = () => {
      startEndPicker.menu = false;
    };

    const onStartEndOk = () => {
      const dates = startEndPicker.value;

      if (dates.length > 1) {
        const fromDate = new Date(dates[0]).toLocaleDateString();
        const toDate = new Date(dates[1]).toLocaleDateString();

        if (![fromDate, toDate].includes('Invalid Date')) {
          startEndDate.value = `${fromDate} ~ ${toDate}`;
          [newListData.startDate, newListData.endDate] = dates;
        }
      }

      startEndPicker.menu = false;
    };

    const closeDialog = () => {
      form.value.reset();
      Object.assign(newListData, defaultData);
      dialog.value = false;
    };

    const saveChanges = async () => {
      const storeAction = props.action === 'create' ? 'createNewList' : 'updateRefList';
      await Store.dispatch(`referenceList/${storeAction}`, newListData);

      closeDialog();
    };

    return {
      dialogTitle,
      newListData,
      onStartEndCancel,
      onStartEndOk,
      startEndDate,
      publishedTemplates,
      rules,
      dialog,
      form,
      closeDialog,
      saveChanges,
      listTypes,
      startEndPicker,
      validateListData,
      isLoading,
      setListData,
    };
  },
});

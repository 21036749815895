var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"700px"},scopedSlots:_vm._u([(_vm.action === 'create')?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"id":"ref-list-create-btn","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" New ")])]}}:{key:"activator",fn:function(ref){
var ondialog = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var ontooltip = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"id":"ref-list-edit-btn","icon":""},on:{"click":_vm.setListData}},'v-btn',attrs,false),Object.assign({}, ontooltip, ondialog)),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])])]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"form"},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.dialogTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"id":"ref-list-name-field","label":"List Name","rules":[_vm.rules.required]},model:{value:(_vm.newListData.name),callback:function ($$v) {_vm.$set(_vm.newListData, "name", $$v)},expression:"newListData.name"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"id":"ref-list-type-field","label":"List Type","items":_vm.listTypes,"disabled":_vm.action === 'edit',"rules":[_vm.rules.required]},model:{value:(_vm.newListData.type),callback:function ($$v) {_vm.$set(_vm.newListData, "type", $$v)},expression:"newListData.type"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"id":"ref-list-templates-field","label":"Select Work Order Templates","multiple":"","return-object":"","item-text":"identifier","item-value":"id","items":_vm.publishedTemplates,"rules":[_vm.rules.required]},model:{value:(_vm.newListData.workOrderTemplates),callback:function ($$v) {_vm.$set(_vm.newListData, "workOrderTemplates", $$v)},expression:"newListData.workOrderTemplates"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onStartEndCancel),expression:"onStartEndCancel"}],ref:_vm.startEndPicker.menu,attrs:{"close-on-content-click":false,"transition":"scale-transition","min-width":"240px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"font-weight-regular",attrs:{"id":"ref-list-startend-field","label":"Start/End Date","readonly":"","rules":[_vm.rules.required, _vm.rules.noDatesOverlap]},model:{value:(_vm.startEndDate),callback:function ($$v) {_vm.startEndDate=$$v},expression:"startEndDate"}},on))]}}]),model:{value:(_vm.startEndPicker.menu),callback:function ($$v) {_vm.$set(_vm.startEndPicker, "menu", $$v)},expression:"startEndPicker.menu"}},[_c('v-date-picker',{attrs:{"color":"primary","scrollable":"","range":""},model:{value:(_vm.startEndPicker.value),callback:function ($$v) {_vm.$set(_vm.startEndPicker, "value", $$v)},expression:"startEndPicker.value"}},[_c('v-spacer'),_c('v-btn',{attrs:{"id":"reflist-startend-picker-cancel-btn","text":"","color":"secondary"},on:{"click":function($event){return _vm.onStartEndCancel()}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"id":"reflist-startend-picker-ok-btn","text":"","color":"primary"},on:{"click":function($event){return _vm.onStartEndOk()}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"id":"ref-list-dialog-cancel-btn","color":"secondary","text":"","disabled":_vm.isLoading},on:{"click":_vm.closeDialog}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"id":"ref-list-dialog-save-btn","color":"primary","text":"","disabled":!_vm.validateListData || _vm.isLoading,"loading":_vm.isLoading},on:{"click":_vm.saveChanges}},[_vm._v(" "+_vm._s(_vm.action === 'create' ? 'Create' : 'Save')+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }





















































































































































































































































































































































































































import {
  reactive,
  ref,
  defineComponent,
  computed,
  onMounted,
} from '@vue/composition-api';
import router from '@/router';
import Store from '@/store';
import { getElementId } from '@/utils';
import { IReferenceListRecord, ITemplateSearchRecord, IVComboboxItem } from '@/types';
import ReferenceListCreateDialog from './ReferenceListCreateDialog.vue';
import ReferenceListDeleteDialog from './ReferenceListDeleteDialog.vue';

export default defineComponent({
  components: {
    ReferenceListCreateDialog,
    ReferenceListDeleteDialog,
  },
  setup() {
    onMounted(() => {
      Store.dispatch('templateList/getPublishedTemplatesData');
    });

    const headers = [
      { text: 'List Name', value: 'name' },
      { text: 'List Type', value: 'type', align: 'start' },
      { text: 'List ID', value: 'id' },
      { text: 'Created By', value: 'createdBy' },
      { text: 'Created On', value: 'createdAt' },
      { text: 'Work Order Templates', value: 'workOrderTemplates' },
      { text: 'Start/End Date', value: 'startEndDate' },
      {
        text: 'Actions', value: 'actions', sortable: false, width: '140px',
      },
    ];

    const paginationChanging = ref(false);

    const listItems = computed(() => Store.getters['referenceList/getData'] as IReferenceListRecord[]);
    const isLoading = computed(() => Store.getters['referenceList/getLoading'] as boolean);
    const pagination = computed(() => Store.getters['referenceList/getPagination']);

    const listTypeOptions: IVComboboxItem[] = [
      { text: 'Labour', value: 'labour' },
      { text: 'Plant', value: 'plant' },
      { text: 'Materials', value: 'material' },
      { text: 'ILOS', value: 'ilos' },
    ];

    const templatesLoading = computed(() => Store.getters['templateList/getLoading']);
    const workOrderTemplateOptions = computed(() => {
      const items = Store.getters['templateList/getPublishedTemplatesData'] as ITemplateSearchRecord[];
      return items.map((template) => ({
        text: template.identifier,
        value: template.id,
      }));
    });

    const filterObject = computed(() => Store.getters['referenceList/getFilter']);

    const openedPanel = ref([]);

    const datePicker = reactive({
      createdAt: {
        value: [] as string[],
        menu: false,
        dialog: false,
      },
      startEndDate: {
        value: [] as string[],
        menu: false,
        dialog: false,
      },
    });

    const loadData = async () => {
      await Store.dispatch('referenceList/loadReferenceLists');
    };

    const onFilterChange = () => {
      Store.dispatch('referenceList/setPaginationPage', 1);
      loadData();
    };

    const getListTypeName = (listTypeValue: string) => {
      const listTypeObj = listTypeOptions.find((item) => item.value === listTypeValue);
      return listTypeObj?.text || '';
    };

    const onCreatedAtCancel = () => {
      datePicker.createdAt.menu = false;
      datePicker.createdAt.dialog = false;
    };

    const onCreatedAtOk = (triggerSearch = true) => {
      const dates = datePicker.createdAt.value;

      if (dates.length > 1) {
        Store.dispatch('referenceList/setFilterCreatedAt', dates);

        if (triggerSearch) loadData();
      }

      datePicker.createdAt.menu = false;
      datePicker.createdAt.dialog = false;
    };

    const onCreatedAtClear = (triggerSearch = true) => {
      datePicker.createdAt.value = [];
      Store.dispatch('referenceList/setFilterCreatedAt', '');
      if (triggerSearch) loadData();
    };

    const onStartEndCancel = () => {
      datePicker.startEndDate.menu = false;
      datePicker.startEndDate.dialog = false;
    };

    const onStartEndOk = (triggerSearch = true) => {
      const dates = datePicker.startEndDate.value;

      if (dates.length > 1) {
        Store.dispatch('referenceList/setFilterStartEndDate', dates);

        if (triggerSearch) loadData();
      }

      datePicker.startEndDate.menu = false;
      datePicker.startEndDate.dialog = false;
    };

    const onStartEndClear = (triggerSearch = true) => {
      datePicker.startEndDate.value = [];
      Store.dispatch('referenceList/setFilterStartEndDate', '');

      if (triggerSearch) loadData();
    };

    const onPageChange = async (page: number) => {
      paginationChanging.value = true;
      await Store.dispatch('referenceList/setPaginationPage', page);
      await loadData();
      paginationChanging.value = false;
    };

    const onItemsPerPageChange = async (itemsPerPage: number) => {
      paginationChanging.value = true;
      await Store.dispatch('referenceList/setPaginationItemsPerPage', itemsPerPage);
      await loadData();
      paginationChanging.value = false;
    };

    const onColSortBy = (sortBy: string) => {
      const sortByValue = sortBy || '';
      Store.dispatch('referenceList/setFilterSortBy', sortByValue);
      loadData();
    };

    const onColSortDesc = (isDesc: boolean[]) => {
      const isDescValue = isDesc ? '-' : '';
      Store.dispatch('referenceList/setFilterIsDesc', isDescValue);
      loadData();
    };

    const goToList = (itemId: string, listType: string) => {
      router.push({
        name: 'RefListItems',
        params: { listType, listId: itemId },
      });
    };

    loadData();

    return {
      headers,
      listTypeOptions,
      templatesLoading,
      listItems,
      openedPanel,
      datePicker,
      filterObject,
      getElementId,
      onCreatedAtCancel,
      onCreatedAtOk,
      onCreatedAtClear,
      onStartEndCancel,
      onStartEndOk,
      onStartEndClear,
      workOrderTemplateOptions,
      isLoading,
      getListTypeName,
      pagination,
      onPageChange,
      onItemsPerPageChange,
      onColSortBy,
      onColSortDesc,
      onFilterChange,
      goToList,
      paginationChanging,
    };
  },
});

































import { defineComponent } from '@vue/composition-api';
import ReferenceListTable from '@/components/ReferenceLists/ReferenceListTable.vue';
import ReferenceListCreateDialog from '@/components/ReferenceLists/ReferenceListCreateDialog.vue';
import TableLookups from '@/components/TableLookups/TableLookups.vue';
import TableLookupsAddDialog from '@/components/TableLookups/TableLookupsAddDialog.vue';

export default defineComponent({
  components: {
    ReferenceListTable,
    ReferenceListCreateDialog,
    TableLookups,
    TableLookupsAddDialog,
  },
  setup() {
    const tab = null;

    return { tab };
  },
});






























































































import {
  defineComponent, reactive, ref, computed,
} from '@vue/composition-api';
import Store from '@/store';
import { ITableLookupsRecord } from '@/types';

export default defineComponent({
  setup() {
    const dialog = ref(false);
    const form = ref();
    const isLoading = computed(() => Store.getters['tableLookups/getLoading']);

    const lookupTypes = computed(() => Store.getters['tableLookups/getLookupTypes']);
    const existingLookups = computed(() => Store.getters['tableLookups/getData'] as ITableLookupsRecord[]);

    const defaultData = {
      type: '',
      key: '',
      value: '',
    };

    const newItemData = reactive(defaultData);

    const startEndDate = ref('');

    const rules = {
      required: (value: string): boolean | string => !!value || 'This field is required',
      maxCharsValue: (value: string): boolean | string => {
        if (!value) return false;
        return value.length <= 100 || 'Max 100 characters';
      },
      maxCharsKey: (value: string): boolean | string => {
        if (!value) return false;
        return value.length <= 30 || 'Max 30 characters';
      },
      uniqueKey: () => {
        const lookupsOfType = existingLookups.value.filter((lookup) => lookup.type === newItemData.type);
        if (!newItemData.type || !newItemData.key || lookupsOfType.length === 0) return true;

        const result = lookupsOfType.some((lookupItem) => lookupItem.key === newItemData.key);
        return !result || 'Lookup key must be unique';
      },
    };

    const validateItemData = computed((): boolean => {
      if (!newItemData.type || !newItemData.key || !newItemData.value) return false;

      const maxCharsValue = rules.maxCharsValue(newItemData.value);
      const maxCharsKey = rules.maxCharsKey(newItemData.key);
      const uniqueKey = rules.uniqueKey();

      return (
        typeof maxCharsValue === 'boolean'
          && maxCharsValue
          && typeof maxCharsKey === 'boolean'
          && maxCharsKey
          && typeof uniqueKey === 'boolean'
          && uniqueKey
      );
    });

    const closeDialog = () => {
      form.value.reset();
      Object.assign(newItemData, defaultData);
      dialog.value = false;
    };

    const saveChanges = async () => {
      await Store.dispatch('tableLookups/createLookupItem', newItemData);

      closeDialog();
    };

    return {
      newItemData,
      startEndDate,
      rules,
      dialog,
      form,
      closeDialog,
      saveChanges,
      lookupTypes,
      validateItemData,
      isLoading,
    };
  },
});

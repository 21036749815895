


























































import {
  defineComponent, ref, computed, PropType,
} from '@vue/composition-api';
import { IReferenceListRecord } from '@/types';
import Store from '@/store';

export default defineComponent({
  props: {
    listData: {
      type: Object as PropType<IReferenceListRecord>,
      default: () => {},
    },
  },
  setup(props) {
    const dialog = ref(false);
    const isLoading = computed(() => Store.getters['referenceList/getLoading']);

    const closeDialog = () => {
      dialog.value = false;
    };

    const deleteList = async () => {
      await Store.dispatch('referenceList/deleteRefList', props.listData.id);
      closeDialog();
    };

    return {
      dialog,
      closeDialog,
      deleteList,
      isLoading,
    };
  },
});
